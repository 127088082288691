let facebookPixelChecks = 0;
const isFacebookLoaded = function() {
    return typeof window.fbq !== 'undefined';
};

const onFacebookPixelLoaded = function(callback: () => void) {
    if (++facebookPixelChecks > 10) {
        return;
    }

    if (!isFacebookLoaded()) {
        setTimeout(function() {
            onFacebookPixelLoaded(callback);
        }, 500);
        return;
    }

    callback();
};

export default onFacebookPixelLoaded;
