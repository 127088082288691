import {targetingCookiesAllowed, performanceCookiesAllowed, functionalCookiesAllowed} from './helpers/cookie';
import sendAnalyticsEvent from './modules/sendAnalyticsEvent';
import onFacebookPixelLoaded from './modules/onFacebookPixelLoaded';
import onLyticsLoaded from './modules/onLyticsLoaded';

window.onFacebookPixelLoaded = onFacebookPixelLoaded;
window.onLyticsLoaded = onLyticsLoaded;
window.targetingCookiesAllowed = targetingCookiesAllowed;
window.performanceCookiesAllowed = performanceCookiesAllowed;
window.functionalCookiesAllowed = functionalCookiesAllowed;
window.sendAnalyticsEvent = sendAnalyticsEvent;
