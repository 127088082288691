let lyticsChecks = 0;
const isLyticsLoaded = function() {
    return typeof window.jstag !== 'undefined';
};

const onLyticsLoaded = function(callback: () => void) {
    if (++lyticsChecks > 10) {
        return;
    }

    if (!isLyticsLoaded()) {
        setTimeout(function() {
            onLyticsLoaded(callback);
        }, 500);
        return;
    }

    callback();
};

export default onLyticsLoaded;
